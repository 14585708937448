<template>
   <div>
	  <b-sidebar
		  id="sidebar-add-new-event"
		  :visible="isEventHandlerSidebarActive"
		  backdrop
		  bg-variant="white"
		  no-header
		  right
		  shadow
		  sidebar-class="sidebar-lg"
		  @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
	  >
		 <template #default="{ hide }">
			<!-- Header -->
			<div
				class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
			>
			   <h5 class="mb-0">
				  {{ eventLocal.id ? $t("contacts.update") : $t("generic.add") }}
				  {{ eventLocal.extendedProps.type }}
			   </h5>
			   <div>
				  <feather-icon
					  v-if="eventLocal.id && eventLocal.extendedProps.type === 'events'"
					  class="cursor-pointer"
					  icon="TrashIcon"
					  @click="
                $emit('remove-event');
                hide();
              "
				  />
				  <feather-icon
					  class="ml-1 cursor-pointer"
					  icon="XIcon"
					  size="16"
					  @click="hide"
				  />
			   </div>
			</div>

			<!-- Body -->
			<validation-observer ref="refFormObserver"
								 #default="{ handleSubmit }">
			   <!-- Form -->
			   <b-form
				   class="p-2"
				   @submit.prevent="handleSubmit(onSubmit)"
				   @reset.prevent="resetForm"
			   >
				  <!-- Title -->
				  <validation-provider
					  #default="validationContext"
					  name="Title"
					  rules="required"
				  >
					 <b-badge v-if="eventLocal.extendedProps.type === 'tasks'"
							  class="mb-1"
							  pill
							  variant="light-primary">
						{{ $t("projects.project-one") }}:
						{{ eventLocal.extendedProps.projectName }}
					 </b-badge>

					 <b-form-group
						 :label="$t('generic.title')"
						 class="required"
						 label-for="event-title"
					 >
						<b-form-input
							id="event-title"
							v-model="eventLocal.title"
							:state="getValidationState(validationContext)"
							autofocus
							trim
						/>

						<b-form-invalid-feedback>
						   {{ $t("errors.the-error-field-is-required") }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <!-- calendar -->
				  <validation-provider
					  v-if="
                eventLocal.extendedProps.type === 'events' ||
                eventLocal.extendedProps.type == ''
              "
					  #default="validationContext"
					  name="Calendar"
					  rules="required"
				  >
					 <b-form-group
						 :label="$t('generic.calendar')"
						 :state="getValidationState(validationContext)"
						 class="required"
						 label-for="calendar"
					 >
						<v-select
							v-model="eventLocal.extendedProps.calendarId"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="$store.getters.getCalendar"
							inputId="id"
							label="title"
						>
						   <template v-slot:no-options>{{
								 $t("errors.no-matching")
													   }}
						   </template>
						   <template #option="{ color, title }">
							  <div
								  :style="{ backgroundColor: color }"
								  class="rounded-circle d-inline-block mr-50"
								  style="height: 10px; width: 10px"
							  />
							  <span> {{ title }}</span>
						   </template>

						   <template #selected-option="{ color, title }">
							  <div
								  :style="{ backgroundColor: color }"
								  class="rounded-circle d-inline-block mr-50"
								  style="height: 10px; width: 10px"
							  />
							  <span> {{ title }}</span>
						   </template>
						</v-select>

						<b-form-invalid-feedback
							:state="getValidationState(validationContext)"
						>
						   {{ $t("errors.the-error-field-is-required") }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <!-- Start Date -->
				  <validation-provider
					  v-if="eventLocal.extendedProps.type != 'todos'"
					  #default="validationContext"
					  name="Start Date"
					  rules="required"
				  >
					 <b-form-group
						 :label="$t('calendar.start-date')"
						 :state="getValidationState(validationContext)"
						 class="required"
						 label-for="start-date"
					 >
						<date-picker
							v-model="eventLocal.extendedProps.startExtend"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY HH:mm"
							type="datetime"
							valueType="YYYY-MM-DD HH:mm"></date-picker>


						<b-form-invalid-feedback
							:state="getValidationState(validationContext)"
						>
						   {{ $t("errors.the-error-field-is-required") }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <!-- Start Date todos -->
				  <validation-provider
					  v-if="eventLocal.extendedProps.type === 'todos'"
					  #default="validationContext"
					  name="Start Date"
					  rules="required"
				  >
					 <b-form-group
						 :label="$t('calendar.start-date')"
						 :state="getValidationState(validationContext)"
						 label-for="start-date"
					 >
						<date-picker
							v-model="eventLocal.extendedProps.startExtend"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY HH:mm"
							type="datetime"
							valueType="YYYY-MM-DD HH:mm"></date-picker>

						<b-form-invalid-feedback
							:state="getValidationState(validationContext)"
						>
						   {{ $t("errors.the-error-field-is-required") }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <!-- End Date -->
				  <b-form-group
					  v-if="
                eventLocal.extendedProps.type !== 'todos' && !eventLocal.allDay
              "
					  :label="$t('calendar.end-date')"
					  class="required"
					  label-for="end-date"
				  >
					 <date-picker
						 v-model="eventLocal.extendedProps.endExtend"
						 :show-time-header="true"
						 class="w-100"
						 format="DD-MM-YYYY HH:mm"
						 type="datetime"
						 valueType="YYYY-MM-DD HH:mm" />

				  </b-form-group>

				  <!-- All Day -->
				  <b-form-group
					  v-if="
                eventLocal.extendedProps.type === 'events' ||
                eventLocal.extendedProps.type == ''
              "
				  >
					 <b-form-checkbox
						 v-model="eventLocal.allDay"
						 inline
						 name="check-button"
						 switch
					 >
						{{ $t("calendar.all-day") }}
					 </b-form-checkbox>
				  </b-form-group>

				  <!-- Completed todos-->
				  <b-form-group v-if="eventLocal.extendedProps.type === 'todos'">
					 <b-form-checkbox
						 v-model="eventLocal.extendedProps.completed"
						 inline
						 name="check-button"
						 switch
					 >
						{{ $t("todos.markcomplete") }}
					 </b-form-checkbox>
				  </b-form-group>

				  <!-- important todos-->
				  <b-form-group v-if="eventLocal.extendedProps.type === 'todos'">
					 <b-form-checkbox
						 v-model="eventLocal.extendedProps.important"
						 inline
						 name="check-button"
						 switch
					 >
						{{ $t("generic.important") }}
					 </b-form-checkbox>
				  </b-form-group>

				  <!-- Guests -->
				  <b-form-group
					  v-if="
                eventLocal.extendedProps.type === 'events' ||
                eventLocal.extendedProps.type == ''
              "
					  :label="$t('settings.partners')"
					  label-for="add-guests"
				  >
					 <v-select
						 v-model="eventLocal.extendedProps.guests"
						 :close-on-select="false"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :options="allParnter"
						 input-id="add-guests"
						 label="name"
						 multiple
					 >
						<template v-slot:no-options>{{
							  $t("errors.no-matching")
													}}
						</template>
						<template #option="{ photo, name, surname }">
						   <b-avatar :src="photo"
									 size="sm" />
						   <span class="ml-50 align-middle"> {{ photo }}</span>
						</template>

						<template #selected-option="{ photo, name, surname }">
						   <b-avatar
							   :src="photo"
							   class="border border-white"
							   size="sm"
						   />
						   <span class="ml-50 align-middle"
						   >{{ surname }} {{ name }}</span
						   >
						</template>
						<template #option="{ photo, name, surname }">
						   <b-avatar
							   :src="photo"
							   class="border border-white"
							   size="sm"
						   />
						   <span class="ml-50 align-middle"
						   >{{ surname }} {{ name }}</span
						   >
						</template>
					 </v-select>
				  </b-form-group>

				  <!-- Partner todos -->
				  <b-form-group
					  v-if="eventLocal.extendedProps.type === 'todos'"
					  :label="$t('settings.partners')"
					  label-for="add-partners"
				  >
					 <v-select
						 v-model="eventLocal.extendedProps.partnerId"
						 :close-on-select="false"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :options="$store.getters.getAllPartners.data"
						 input-id="add-partners"
						 label="name"
						 multiple=""
					 >
						<template v-slot:no-options>{{
							  $t("errors.no-matching")
													}}
						</template>
						<template #option="{ photo, name, surname }">
						   <b-avatar :src="photo"
									 size="sm" />
						   <span class="ml-50 align-middle"> {{ photo }}</span>
						</template>

						<template #selected-option="{ photo, name, surname }">
						   <b-avatar
							   :src="photo"
							   class="border border-white"
							   size="sm"
						   />
						   <span class="ml-50 align-middle"
						   >{{ surname }} {{ name }}</span
						   >
						</template>
						<template #option="{ photo, name, surname }">
						   <b-avatar
							   :src="photo"
							   class="border border-white"
							   size="sm"
						   />
						   <span class="ml-50 align-middle"
						   >{{ surname }} {{ name }}</span
						   >
						</template>
					 </v-select>
				  </b-form-group>

				  <!-- tags todos -->
				  <b-form-group
					  v-if="eventLocal.extendedProps.type === 'todos'"
					  :label="$t('settings.tags')"
					  label-for="add-tags"
				  >
					 <v-select
						 v-model="eventLocal.extendedProps.tags"
						 :close-on-select="false"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :options="$store.getters.getTags"
						 input-id="add-tags"
						 label="name"
						 multiple=""
					 >
						<template v-slot:no-options>{{
							  $t("errors.no-matching")
													}}
						</template>
						<template #option="{ name }">
						   <span class="ml-50 align-middle">{{ name }} </span>
						</template>
					 </v-select>
				  </b-form-group>

				  <!-- status -->
				  <b-form-group
					  v-if="
                eventLocal.extendedProps.type === 'tasks' ||
                eventLocal.extendedProps.type === 'projects'
              "
					  :label="$t('generic.status')"
				  >
					 <v-select
						 v-model="eventLocal.extendedProps.status"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :options="statusOptions"
						 :reduce="(option) => option.value"
						 class="invoice-filter-select"
						 label="value"
						 placeholder="Select Status"
					 >
						<template v-slot:no-options>{{
							  $t("errors.no-matching")
													}}
						</template>
					 </v-select>
				  </b-form-group>

				  <!-- Location -->
				  <b-form-group
					  v-if="
                eventLocal.extendedProps.type === 'events' ||
                eventLocal.extendedProps.type == ''
              "
					  :label="$t('calendar.location')"
					  label-for="event-location"
				  >
					 <b-form-input
						 id="event-location"
						 v-model="eventLocal.extendedProps.location"
						 trim
					 />
				  </b-form-group>

				  <!-- Textarea Events -->
				  <b-form-group
					  v-if="
                eventLocal.extendedProps.type === 'events' ||
                eventLocal.extendedProps.type == '' ||
                eventLocal.extendedProps.type === 'todos'
              "
					  :label="$t('contacts.notes')"
					  label-for="event-description"
				  >
					 <b-form-textarea v-model="eventLocal.extendedProps.description" />
				  </b-form-group>

				  <!-- Textarea notes -->
				  <b-form-group
					  v-if="eventLocal.extendedProps.type == 'tasks'"
					  :label="$t('contacts.notes')"
					  label-for="event-description"
				  >
					 <b-form-textarea v-model="eventLocal.extendedProps.notes" />
				  </b-form-group>

				  <!-- Contact  Project-->
				  <b-form-group
					  v-if="eventLocal.extendedProps.type === 'projects'"
					  :label="$t('generic.client')"
					  label-for="add-contact"
				  >
					 <v-select
						 v-model="eventLocal.extendedProps.contactId"
						 :close-on-select="false"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :options="$store.getters.getAllContacts"
						 input-id="add-contact"
						 label="name"
					 >
						<template v-slot:no-options>{{
							  $t("errors.no-matching")
													}}
						</template>
						<template #option="{ photo, name, surname, color }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname, name)"
							   :variant="getColor(color)"
							   size="sm"
						   />
						   <span class="ml-50 align-middle"> {{ photo }}</span>
						</template>

						<template #selected-option="{ photo, name, surname, color }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname, name)"
							   :variant="getColor(color)"
							   class="border border-white"
							   size="sm"
						   />
						   <span class="ml-50 align-middle"
						   >{{ surname }} {{ name }}</span
						   >
						</template>
						<template #option="{ photo, name, surname, color }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname, name)"
							   :variant="getColor(color)"
							   class="border border-white"
							   size="sm"
						   />
						   <span class="ml-50 align-middle"
						   >{{ surname }} {{ name }}</span
						   >
						</template>
					 </v-select>
				  </b-form-group>

				  <!-- Category  Project-->
				  <b-form-group
					  v-if="eventLocal.extendedProps.type === 'projects'"
					  :label="$t('generic.category')"
					  label-for="add-category"
				  >
					 <v-select
						 v-model="eventLocal.extendedProps.categoryId"
						 :close-on-select="false"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :options="$store.getters.getSettingProject"
						 input-id="add-category"
						 label="title"
					 >
						<template v-slot:no-options>{{
							  $t("errors.no-matching")
													}}
						</template>
						<template #selected-option="{ title }">
						   <span class="ml-50 align-middle">{{ title }} </span>
						</template>
						<template #option="{ title }">
						   <span class="ml-50 align-middle">{{ title }} </span>
						</template>
					 </v-select>
				  </b-form-group>

				  <!-- Form Actions -->
				  <div class="d-flex mt-2">
					 <b-button
						 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						 class="mr-2"
						 type="submit"
						 variant="primary"
					 >
						{{ eventLocal.id ? $t("generic.save") : $t("generic.add") }}
					 </b-button>
				  </div>
			   </b-form>
			</validation-observer>
		 </template>
	  </b-sidebar>
   </div>
</template>

<script>
import {
   BAvatar,
   BButton,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormTextarea,
   BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required, url} from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import  {ref, toRefs, watchEffect,watch} from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import axiosIns from "@/libs/axios";
import caldoSettings from "@/caldoSettings";
import store from "@/store";
import {avatarText} from "@core/utils/filter";
import {BBadge} from 'bootstrap-vue'
import moment from "moment";

export default {
   components: {
	  BButton,
	  BSidebar,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BFormCheckbox,
	  BFormTextarea,
	  BAvatar,
	  vSelect,
	  flatPickr,
	  ValidationProvider,
	  BFormInvalidFeedback,
	  ValidationObserver,
	  BBadge
   },
   directives: {
	  Ripple,
   },
   model: {
	  prop: "isEventHandlerSidebarActive",
	  event: "update:is-event-handler-sidebar-active",
   },
   props: {
	  isEventHandlerSidebarActive: {
		 type: Boolean,
		 required: true,
	  },
	  event: {
		 type: Object,
		 required: true,
	  },
	  clearEventData: {
		 type: Function,
		 required: true,
	  },
   },
   data() {
	  return {
		 required,
		 email,
		 url,
	  };
   },
   setup(props, {emit}) {
	  /*
		 ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
		 ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
		 * The Trick:

		 * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
		 * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
		 * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

		 ? Behind The Scene
		 ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
		 ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
		*/
	  const clearFormData = ref(null);
	  const allParnter = ref([]);
	  const statusOptions = caldoSettings.statusOptions;

	  axiosIns.get(`/partner`, {params: {rpp: 100}}).then((r) => {
		 allParnter.value = r.data.data;
	  });

	


	  function fullName(name, surname) {
		 return avatarText(name, surname);
	  }

	  function getColor(color) {
		 return caldoSettings.getColor(color);
	  }

	  function test(value){
		 eventLocal.value.extendedProps.endExtend = moment(value).add(1, 'hour').format('YYYY-MM-DD HH:mm')
	  }

	  store.dispatch("fetchAllPartners", 1);
	  store.dispatch("fetchTodos");
	  store.dispatch("fetchAllContacts");

	  const {
		 eventLocal,
		 resetEventLocal,
		 calendarOptions,

		 // UI
		 onSubmit,
		 guestsOptions,
	  } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

	  const {refFormObserver, getValidationState, resetForm, clearForm} =
		  formValidation(resetEventLocal, props.clearEventData);

	  clearFormData.value = clearForm;




	  return {
		 // Add New Event
		 eventLocal,
		 calendarOptions,
		 onSubmit,
		 test,
		 guestsOptions,
		 allParnter,
		 statusOptions,
		 // Form Validation
		 resetForm,

		 getColor,
		 fullName,
		 refFormObserver,
		 getValidationState,
	  };
   },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
