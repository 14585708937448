<template>
   <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
	  <div class="p-2">
		 <b-button
			 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			 :aria-expanded="String(isEventHandlerSidebarActive)"
			 aria-controls="sidebar-add-new-event"
			 block
			 variant="primary"
			 @click="$emit('update:isEventHandlerSidebarActive', true)"
		 >
			{{ $t('generic.add') }}
		 </b-button>
		 <div class="mt-3">
			<h5 class="app-label section-label mb-1">
			   <span class="align-middle">{{ $t('generic.calendars-2') }}</span>
			</h5>
			<!--			<b-form-checkbox-->
			<!--				v-model="checkAll"-->
			<!--				class="mb-1"-->
			<!--			>-->
			<!--			   {{ $t('generic.all') }}-->
			<!--			</b-form-checkbox>-->
			<b-form-group>
			   <b-form-checkbox-group
				   v-model="selectedCalendars"
				   name="event-filter"
				   stacked
			   >
				  <b-form-checkbox
					  v-for="item in calendarOptions"
					  v-show="item.label !== 'Events'"
					  :key="item.id"
					  :class="`custom-control-${getColor(item.color)}`"
					  :value="item.label"
					  class="mb-1"
					  name="event-filter"
					  @change="$emit('checkbox')"
				  >
					 {{ item.label }}
				  </b-form-checkbox>
			   </b-form-checkbox-group>
			</b-form-group>
		 </div>
	  </div>
	  <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
   </div>
</template>

<script>
import {BButton, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BImg,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'
import axiosIns from "@/libs/axios";
import caldoSettings from "@/caldoSettings";

export default {
   directives: {
	  Ripple,
   },
   components: {
	  BButton,
	  BImg,
	  BFormCheckbox,
	  BFormGroup,
	  BFormCheckboxGroup,
   },
   props: {
	  isEventHandlerSidebarActive: {
		 type: Boolean,
		 require: true,
	  },
   },
   setup() {
	  const {
		 calendarOptions,
		 selectedCalendars,
		 checkAll,
	  } = useCalendarSidebar()

	  function getColor(color) {

		 if (color.split('').shift() === '#') {


			const colorName = _.find(caldoSettings.getCustomLight('all'), {'value': color})

			return colorName.text

		 }
		 return color;
		 // Background Color
	  }

	  axiosIns.get(`/calendar`).then(r => {
		 r.data.map(el => {
			const index = selectedCalendars.value.findIndex(object => object.title === el.title);
			if (index === -1) {
			   selectedCalendars.value.push(el.title);
			}
		 })
	  })
	  return {
		 getColor,
		 calendarOptions,
		 selectedCalendars,
		 checkAll,
	  }
   },
}
</script>

<style>

</style>
