// Full calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import elLocale from '@fullcalendar/core/locales/el';
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {computed, onMounted, ref, watch} from '@vue/composition-api'
import store from '@/store'
import _ from "lodash";
import caldoSettings from "@/caldoSettings";
import moment from "moment";

export default function userCalendar(counter) {
    // Use toast
    const toast = useToast()
    // ------------------------------------------------
    // refCalendar
    // ------------------------------------------------
    const refCalendar = ref(null)

    // ------------------------------------------------
    // calendarApi
    // ------------------------------------------------
    let calendarApi = null
    onMounted(() => {
        calendarApi = refCalendar.value.getApi()

        setTimeout(() => {
            refetchEvents()
        }, 1000)
    })

    // -t-----------------------------------------------
    // calendars
    // ------------------------------------------------
    // const calendarsColor = {
    //     Business: 'primary',
    //     Holiday: 'success',
    //     Personal: 'danger',
    //     Family: 'warning',
    //     ETC: 'info',
    // }
    //
    const calendarsColor = {
        Events: 'danger',
        Projects: 'primary',
        Todos: 'danger',
        Tasks: 'warning',
    }

    // ------------------------------------------------
    // event
    // ------------------------------------------------
    const blankEvent = {
        title: '',
        startDate: '',
        endDate: '',
        test: '',
        allDay: false,
        url: '',
        status: '',
        extendedProps: {
            calendar: '',
            guests: [],
            location: '',
            description: '',
            type: ''
        },
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
        event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    // *===========================================================================---*
    // *--------- calendar API Function/Utils --------------------------------------------*
    // Template Future Update: We might move this utils function in its own file
    // *===========================================================================---*

    // ------------------------------------------------
    // (UI) addEventInCalendar
    // ? This is useless because this just add event in calendar and not in our data
    // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
    // ------------------------------------------------
    // const addEventInCalendar = eventData => {
    //   toast({
    //     component: ToastificationContent,
    //     position: 'bottom-right',
    //     props: {
    //       title: 'Event Added',
    //       icon: 'CheckIcon',
    //       variant: 'success',
    //     },
    //   })
    //   calendarApi.addEvent(eventData)
    // }

    // ------------------------------------------------
    // (UI) updateEventInCalendar
    // ------------------------------------------------
    const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
        toast({
            component: ToastificationContent,
            props: {
                title: 'Ενημερώθηκε',
                icon: 'CheckIcon',
                variant: 'success',
            },
        })

        const existingEvent = calendarApi.getEventById(updatedEventData.id)

        // --- Set event properties except date related ----- //
        // ? Docs: https://fullcalendar.io/docs/Event-setProp
        // dateRelatedProps => ['start', 'end', 'allDay']
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < propsToUpdate.length; index++) {
            const propName = propsToUpdate[index]
            existingEvent.setProp(propName, updatedEventData[propName])
        }

        // --- Set date related props ----- //
        // ? Docs: https://fullcalendar.io/docs/Event-setDates
        existingEvent.setDates(updatedEventData.start, updatedEventData.end, {allDay: updatedEventData.allDay})

        // --- Set event's extendedProps ----- //
        // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < extendedPropsToUpdate.length; index++) {
            const propName = extendedPropsToUpdate[index]
            existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
        }
    }

    // ------------------------------------------------
    // (UI) removeEventInCalendar
    // ------------------------------------------------
    const removeEventInCalendar = eventId => {
        toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
                title: 'Διαγράφηκε',
                icon: 'CheckIcon',
                variant: 'danger',
            },
        })
        refetchEvents();
        // calendarApi.getEventById(eventId).remove()
    }

    // ------------------------------------------------
    // grabEventDataFromEventApi
    // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
    // ! You need to update below function as per your extendedProps
    // ------------------------------------------------
    const grabEventDataFromEventApi = eventApi => {


        const {
            id,
            title,
            start,
            end,
            // eslint-disable-next-line object-curly-newline
            extendedProps: {
                calendar,
                guests,
                location,
                description,
                type,
                startExtend,
                endExtend,
                status,
                notes,
                important,
                calendarId,
                completed,
                categoryId,
                partnerId,
                projectName,
                contactId,
                tags
            },
            allDay,
        } = eventApi

        return {
            id,
            title,
            start,
            end,
            extendedProps: {
                calendar,
                guests,
                calendarId,
                startExtend,
                endExtend,
                location,
                description,
                type,
                status,
                notes,
                important,
                partnerId,
                completed,
                projectName,
                categoryId,
                contactId,
                tags
            },
            allDay,
        }
    }

    const hasData = ref();
    // ------------------------------------------------
    // addEvent
    // ------------------------------------------------
    const addEvent = eventData => {
        store.dispatch('calendar/addEvent', {event: eventData}).then(() => {
            // eslint-disable-next-line no-use-before-define
            refetchEvents()
        })
    }

    // ------------------------------------------------
    // updateEvent
    // ------------------------------------------------
    const updateEvent = eventData => {
        store.dispatch('calendar/updateEvent', {event: eventData}).then(response => {


            toast({
                component: ToastificationContent,
                props: {
                    title: 'Ενημερώθηκε',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
            refetchEvents()
            // updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
        })
    }

    // ------------------------------------------------
    // removeEvent
    // ------------------------------------------------
    const removeEvent = async () => {
        const eventId = event.value.id
        const {isConfirmed} = await window.swall({
            title: 'Είστε σίγουρος?',
            text: 'ΠΡΟΣΟΧΗ! Η διαγραφή είναι μόνιμη και δεν μπορεί να γίνει επαναφορά',
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7367f0',
            cancelButtonColor: '#82868b',
            confirmButtonText: 'Διαγραφή',
            cancelButtonText: 'Ακύρωση',
            reverseButtons: true,
            buttonsStyling: true,
            animation: true
        });
        if (isConfirmed) {
            store.dispatch('calendar/removeEvent', {id: eventId}).then(() => {
                removeEventInCalendar(eventId)
            })
        }

    }

    // ------------------------------------------------
    // refetchEvents
    // ------------------------------------------------
    const refetchEvents = () => {
        calendarApi.refetchEvents()
    }

    // ------------------------------------------------
    // selectedCalendars
    // ------------------------------------------------
    const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

    watch(counter, (newValue) => {


        refetchEvents()
    })

    function arrayRemove(arr, value) {

        return arr.filter(function (ele) {
            return ele != value;
        });
    }

    // --------------------------------------------------------------------------------------------------
    // AXIOS: fetchEvents
    // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
    // --------------------------------------------------------------------------------------------------
    const fetchEvents = (info, successCallback) => {
        // If there's no info => Don't make useless API call
        if (!info) return

        let selectedCalendarsTitle = selectedCalendars.value;

        let forDeletion = ['Events', 'Projects', 'Todos', 'Tasks']


        selectedCalendarsTitle = selectedCalendarsTitle.filter(item => !forDeletion.includes(item))

        let calendarIds = []


        selectedCalendarsTitle.forEach(el => {
            let id = _.find(store.getters.getCalendar, {title: el})?.id
            if (!calendarIds.includes(id)) {
                calendarIds.push(id)
            } else {
                calendarIds.splice(calendarIds.indexOf(id), 1);
            }
        })


        // Fetch Events from API endpoint
        let events = [];


        // if (calendarIds.length) {
        if (true) {


            store
                .dispatch('calendar/fetchEvents', {
                    calendars: selectedCalendarsTitle,
                    ids: calendarIds,
                })
                .then(response => {

                    if (Object.values(selectedCalendars.value,).includes("Events")) {
                        _.mapValues(response.data.events, (el, idx) => {
                            const calendar = _.find(store.getters.getCalendar, {id: el.calendarId})

                            // console.log( el['startDate'])

                            //events
                            events.push({
                                allDay: el['fullDay'],
                                end: el['endDate'],
                                extendedProps: {
                                    calendarId: calendar,
                                    calendar: calendar.color,
                                    type: 'events',
                                    startExtend: el['startDate'],
                                    endExtend: el['endDate'],
                                },
                                id: el['id'],
                                start: el['startDate'],
                                title: el['title'],
                                location: el['location'],
                                description: el['description'],
                                guests: el['partners'],
                                // url: "",
                            })
                        })
                    }
                    if (Object.values(selectedCalendars.value,).includes("Tasks")) {
                        // //tasks
                        _.mapValues(response.data.tasks, (el, idx) => {
                            // const calendar = _.find(store.getters.getCalendar, {id: el.calendarId})
                            events.push({
                                start: new Date(el['startDate']),
                                end: new Date(el['endDate']),
                                extendedProps: {
                                    calendar: 'success',
                                    project: el.projectId,
                                    type: 'tasks',
                                    startExtend: el['startDate'],
                                    endExtend: el['endDate'],
                                    status: el['status'],
                                    notes: el['notes'],
                                    projectName: el['project'].title
                                },
                                id: el['id'],
                                title: el['title'],
                                // url: "",
                            })
                        })
                    }

                    if (Object.values(selectedCalendars.value,).includes("Todos")) {
                        // //todos
                        _.mapValues(response.data.todos, (el, idx) => {
                            // const partner = _.find(store.getters.getAllPartners.data, {id: el.partnerId})


                            events.push({
                                start: new Date(el['dueDate']),
                                // end: new Date(el['dueDate']),
                                extendedProps: {
                                    project: el.projectId,
                                    calendar: 'warning',
                                    type: 'todos',
                                    status: el['status'],
                                    startExtend: el['dueDate'],
                                    description: el['description'],
                                    important: !!el['important'],
                                    completed: !!el['completed'],
                                    partnerId: el['partner'],
                                    tags: el['tags'],
                                },
                                id: el['id'],
                                title: el['title'],
                                // url: "",
                            })
                        })
                    }

                    // project
                    if (Object.values(selectedCalendars.value,).includes("Projects")) {
                        _.mapValues(response.data.projects, (el, idx) => {
                            const contact = _.find(store.getters.getAllContacts, {id: el.contactId})
                            const category = _.find(store.getters.getSettingProject, {id: el.categoryId})

                            events.push({
                                start: new Date(el['startDate']),
                                end: new Date(el['deadline']),
                                extendedProps: {
                                    calendar: 'primary',
                                    contactId: contact,
                                    type: 'projects',
                                    startExtend: el['startDate'],
                                    endExtend: el['deadline'],
                                    status: el['status'],
                                    categoryId: category,
                                },
                                id: el['id'],
                                title: el['title'],
                                // url: "",
                            })
                        })
                    }

                    successCallback(events)
                })
                .catch((err) => {
                    console.log(err)
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching calendar events',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                })
        }
    }



    // ------------------------------------------------------------------------
    // calendarOptions
    // * This isn't considered in UI because this is the core of calendar app
    // ------------------------------------------------------------------------
    const calendarOptions = ref({
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            start: 'sidebarToggle, prev,next, title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        locale: elLocale,
        events: fetchEvents,

        /*
          Enable dragging and resizing event
          ? Docs: https://fullcalendar.io/docs/editable
        */
        editable: true,

        /*
          Enable resizing event from start
          ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
        */
        eventResizableFromStart: true,

        /*
          Automatically scroll the scroll-containers during event drag-and-drop and date selecting
          ? Docs: https://fullcalendar.io/docs/dragScroll
        */
        dragScroll: true,

        /*
          Max number of events within a given day
          ? Docs: https://fullcalendar.io/docs/dayMaxEvents
        */
        dayMaxEvents: 2,
        // eventColor: 'rgba(255,0,0,0.12)',
        // textColor: 'rgba(255,0,0,0.12)',
        /*
          Determines if day names and week names are clickable
          ? Docs: https://fullcalendar.io/docs/navLinks
        */
        navLinks: true,

        eventClassNames({event: calendarEvent}) {
            // eslint-disable-next-line no-underscore-dangle
            const colorName = calendarEvent._def.extendedProps.calendar

            if (colorName.split('').shift() === '#') {


                const color = _.find(caldoSettings.getCustomLight('all'), {'value': colorName})

                return [
                    // Background Color
                    `bg-light-${color.text}`,
                ]
            }

            return [
                // Background Color
                `bg-light-${colorName}`,
            ]
        },
        eventClick({event: clickedEvent}) {

            // * Only grab required field otherwise it goes in infinity loop
            // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
            event.value = grabEventDataFromEventApi(clickedEvent)


            // eslint-disable-next-line no-use-before-define
            isEventHandlerSidebarActive.value = true
        },

        customButtons: {
            sidebarToggle: {
                // --- This dummy text actual icon rendering is handled using SCSS ----- //
                text: 'sidebar',
                click() {
                    // eslint-disable-next-line no-use-before-define
                    isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
                },
            },
        },


        dateClick(info) {
            /*
              ! Vue3 Change
              Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
              ```
              event.value.start = info.date
              ```
            */
            event.value = JSON.parse(JSON.stringify(Object.assign(event.value, {start: info.date})))
            // eslint-disable-next-line no-use-before-define
            isEventHandlerSidebarActive.value = true
        },

        /*
          Handle event drop (Also include dragged event)
          ? Docs: https://fullcalendar.io/docs/eventDrop
          ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
        */
        eventDrop({event: droppedEvent}) {
            updateEvent(grabEventDataFromEventApi(droppedEvent))
        },

        /*
          Handle event resize
          ? Docs: https://fullcalendar.io/docs/eventResize
        */
        eventResize({event: resizedEvent}) {
            updateEvent(grabEventDataFromEventApi(resizedEvent))
        },

        // Get direction from app state (store)
        direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
        rerenderDelay: 350,
    })

    // ------------------------------------------------------------------------

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const isEventHandlerSidebarActive = ref(false)

    const isCalendarOverlaySidebarActive = ref(false)

    return {
        refCalendar,
        isCalendarOverlaySidebarActive,
        calendarOptions,
        event,
        clearEventData,
        addEvent,
        updateEvent,
        removeEvent,
        refetchEvents,
        fetchEvents,

        // ----- UI ----- //
        isEventHandlerSidebarActive,
    }
}
