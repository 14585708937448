import store from '@/store'

import {computed} from '@vue/composition-api'
import axiosIns from "@/libs/axios";

export default function useCalendarSidebar() {
    // ------------------------------------------------
    // calendarOptions
    // ------------------------------------------------
    const calendarOptions = computed(() => store.state.calendar.calendarOptions)


    axiosIns.get(`/calendar`).then(r => {
        r.data.map(el => {
            // console.log(el.title)
            // console.log(calendarOptions.value)
            const isFound = calendarOptions.value.some(element => {
                if (element.label === el.title) {
                    return true;
                }

                return false;
            });
            if (!isFound) {
                calendarOptions.value.push({
                    id: el.id,
                    label: el.title,
                    color: el.color
                });
            }
        })
    })


    // ------------------------------------------------
    // selectedCalendars
    // ------------------------------------------------
    const selectedCalendars = computed({
        get: () => store.state.calendar.selectedCalendars,
        set: async val => {


            store.commit('calendar/SET_SELECTED_EVENTS', val)
        },
    })

    // ------------------------------------------------
    // checkAll
    // ------------------------------------------------
    const checkAll = computed({
        /*
          GET: Return boolean `true` => if length of options matches length of selected filters => Length matches when all events are selected
          SET: If value is `true` => then add all available options in selected filters => Select All
               Else if => all filters are selected (by checking length of both array) => Empty Selected array  => Deselect All
        */

        get: function () {
            selectedCalendars.value = selectedCalendars.value.filter((c, index) => {
                return selectedCalendars.value.indexOf(c) === index;
            });

            return selectedCalendars.value.length === calendarOptions.value.length
        },
        set: val => {
            if (val) {
                selectedCalendars.value = calendarOptions.value.map(i => i.label)
            } else if (selectedCalendars.value.length === calendarOptions.value.length) {
                selectedCalendars.value = []
            }
        },
    })

    return {
        calendarOptions,
        selectedCalendars,
        checkAll,
    }
}
