import {ref, watch} from '@vue/composition-api'
import store from '@/store'
import moment from "moment";

export default function useCalendarEventHandler(props, clearForm, emit) {
    // ------------------------------------------------
    // eventLocal
    // ------------------------------------------------
    const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
    const resetEventLocal = () => {


        eventLocal.value = JSON.parse(JSON.stringify(props.event.value))


        if (eventLocal.value.extendedProps.type == '') {
            eventLocal.value.extendedProps.startExtend = moment(eventLocal.value.start).add(-15, 'hour').format('YYYY-MM-DD HH:mm')
            eventLocal.value.extendedProps.endExtend = moment(eventLocal.value.start).add(-14, 'hour').format('YYYY-MM-DD HH:mm')
        }


        // eventLocal.value.end = moment(eventLocal.value.start).add(1, 'hours');
    }
    watch(props.event, async () => {
        await resetEventLocal()


    })



    // ------------------------------------------------
    // isEventHandlerSidebarActive
    // * Clear form if sidebar is closed
    // ------------------------------------------------
    watch(props.isEventHandlerSidebarActive, val => {
        // ? Don't reset event till transition is finished
        if (!val) {
            setTimeout(() => {
                clearForm.value()
            }, 350)
        }
    })
    // ------------------------------------------------
    // calendarOptions
    // ------------------------------------------------
    const calendarOptions = store.dispatch('fetchCalendar')

    const onSubmit = () => {
        const eventData = JSON.parse(JSON.stringify(eventLocal))

        // * If event has id => Edit Event
        // Emit event for add/update event
        if (props.event.value.id) emit('update-event', eventData.value)
        else emit('add-event', eventData.value)

        // Close sidebar
        emit('update:is-event-handler-sidebar-active', false)
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    // ------------------------------------------------
    // guestOptions
    // ------------------------------------------------

    /* eslint-disable global-require */
    let guestsOptions
    /* eslint-enable global-require */






    return {
        eventLocal,
        resetEventLocal,
        calendarOptions,

        // UI
        guestsOptions,
        onSubmit,
    }
}
