import axios from '@axios'
import axiosIns from "@/libs/axios";
import _ from "lodash";
import moment from "moment";

function removeEmpty(obj) {

    return _.omitBy(obj, (v, idx) => {
        if (idx === 'deadline' && !v) {
            return null;
        }
        if (idx !== 'photo' || idx !== 'fullDay' || idx !== 'partners' || idx !== 'deadline' || idx !== 'repeatable') {
            return _.isUndefined(v) || _.isNull(v) || v == '' || v == "";
        }
    });
}

export default {
    namespaced: true,
    state: {
        calendarOptions: [
            {
                color: 'danger',
                label: 'Events',
            },
            {
                color: 'primary',
                label: 'Projects',
            },
            {
                color: 'warning',
                label: 'Todos',
            },
            {
                color: 'success',
                label: 'Tasks',
            },
        ],
        selectedCalendars: ['Events', 'Projects', 'Todos', 'Tasks'],
    },
    getters: {},
    mutations: {
        SET_SELECTED_EVENTS(state, val) {
            state.selectedCalendars = val
        },
    },
    actions: {

        async fetchEvents(ctx, {calendars, ids}, state) {

            if (!calendars.length) {
                ids.push(0);
            }
            calendars = calendars.filter((c, index) => {
                return calendars.indexOf(c) === index;
            });


            return new Promise((resolve, reject) => {
                axiosIns.get('calendar-events', {
                    params: {
                        "dateFrom": moment().subtract(1, 'year').format('YYYY-MM-DD'),
                        "dateTo": moment().add(1, 'year').format('YYYY-MM-DD'),
                        "calendarIds": ids
                    }
                })
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },

        addEvent(ctx, {event}) {
            return new Promise((resolve, reject) => {
                let eventAdded = {
                    "title": event.title,
                    "calendarId": parseInt(event.extendedProps.calendarId.id),
                    "startDate": event.extendedProps.startExtend ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm') : null,

                    "endDate": event.extendedProps.endExtend ? moment(event.extendedProps.endExtend).format('YYYY-MM-DD HH:mm') : null,
                    "fullDay": event.allDay,
                    "location": event.extendedProps.location,
                    "description": event.extendedProps.description,
                    "partners": _.mapValues(event.extendedProps.guests, el => el.id),
                }

                axios
                    .post('/calendar-event', eventAdded)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateEvent(ctx, {event}) {
            let updatedEvent;
            if (event.extendedProps.type === 'events') {


                let startDate = event.extendedProps.startExtend ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm') : null;
                let endDate = event.allDay
                    ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm')
                    : moment(event.extendedProps.endExtend).format('YYYY-MM-DD HH:mm');

                const isDrag = moment(event.start, 'dddd, MMMM Do YYYY, h:mm:ss a', true).isValid();

                if (isDrag){
                     startDate = event.start ? moment(event.start).format('YYYY-MM-DD HH:mm') : null;
                     endDate = event.allDay
                        ? moment(event.start).format('YYYY-MM-DD HH:mm')
                        : moment(event.end).format('YYYY-MM-DD HH:mm');
                }

                updatedEvent = {
                    "title": event.title,
                    "calendarId": parseInt(event.extendedProps.calendarId.id),
                    "startDate": startDate,
                    "endDate":endDate,
                    "fullDay": event.hasOwnProperty('allDay') ? event.allDay : false,
                    "location": event.extendedProps.location,
                    "description": event.extendedProps.description,
                    "partners": _.mapValues(event.extendedProps.guests, el => el.id),
                }
                return new Promise((resolve, reject) => {
                    axios
                        .put(`/calendar-event/${event.id}`, (updatedEvent))
                        .then(response => resolve(response))
                        .catch(error => console.log(error))
                })


            } else if (event.extendedProps.type === 'tasks') {

                let startDate = event.extendedProps.startExtend ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm') : null;
                let endDate = event.allDay
                    ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm')
                    : moment(event.extendedProps.endExtend).format('YYYY-MM-DD HH:mm');

                const isDrag = moment(event.start, 'dddd, MMMM Do YYYY, h:mm:ss a', true).isValid();

                if (isDrag){
                    startDate = event.start ? moment(event.start).format('YYYY-MM-DD HH:mm') : null;
                    endDate = event.allDay
                        ? moment(event.start).format('YYYY-MM-DD HH:mm')
                        : moment(event.end).format('YYYY-MM-DD HH:mm');
                }


                updatedEvent = {
                    "title": event.title,
                    "status": event.extendedProps.status,
                    "startDate": startDate,
                    "endDate":endDate,
                    "notes": event.extendedProps.notes,
                    "partners": _.mapValues(event.extendedProps.guests, el => el.id),
                }
                return new Promise((resolve, reject) => {
                    axios
                        .put(`/project-task/${event.id}`, removeEmpty(updatedEvent))
                        .then(response => resolve(response))
                        .catch(error => console.log(error))
                })
            } else if (event.extendedProps.type === 'todos') {

                let startDate = event.extendedProps.startExtend ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm') : null;


                const isDrag = moment(event.start, 'dddd, MMMM Do YYYY, h:mm:ss a', true).isValid();

                if (isDrag){
                    startDate = event.start ? moment(event.start).format('YYYY-MM-DD HH:mm') : null;

                }

                updatedEvent = {
                    "title": event.title,
                    "partnerId": event.extendedProps.partnerId?.id,
                    "dueDate":startDate,
                    "description": event.extendedProps.description,
                    "completed": event.extendedProps.completed,
                    "important": event.extendedProps.important,
                    "tags": _.mapValues(event.extendedProps.tags, el => el.id),
                }
                return new Promise((resolve, reject) => {
                    axios
                        .put(`/todo/${event.id}`, removeEmpty(updatedEvent))
                        .then(response => resolve(response))
                        .catch(error => console.log(error))
                })
            } else if (event.extendedProps.type === 'projects') {

                let startDate = event.extendedProps.startExtend ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm') : null;
                let endDate = event.allDay
                    ? moment(event.extendedProps.startExtend).format('YYYY-MM-DD HH:mm')
                    : moment(event.extendedProps.endExtend).format('YYYY-MM-DD HH:mm');

                const isDrag = moment(event.start, 'dddd, MMMM Do YYYY, h:mm:ss a', true).isValid();

                if (isDrag){
                    startDate = event.start ? moment(event.start).format('YYYY-MM-DD HH:mm') : null;
                    endDate = event.allDay
                        ? moment(event.start).format('YYYY-MM-DD HH:mm')
                        : moment(event.end).format('YYYY-MM-DD HH:mm');
                }


                updatedEvent = {
                    "id": event.id,
                    "title": event.title,
                    "startDate": startDate,
                    "deadline": endDate,
                    "categoryId": event.extendedProps.categoryId?.id,
                    "contactId": event.extendedProps.contactId?.id,
                    // "contactId": event.extendedProps.partnerId?.id,
                    // "categoryId": event.extendedProps.important,
                }
                return new Promise((resolve, reject) => {
                    axios
                        .put(`/project`, removeEmpty(updatedEvent))
                        .then(response => resolve(response))
                        .catch(error => console.log(error))
                })
            }


        },
        async removeEvent(ctx, {id}) {


            return new Promise((resolve, reject) => {
                axios
                    .delete(`/calendar-event/${id}`)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })


        },
    },
}
